"use client";
import Image from "next/image";
import styles from "./page.module.css";
import dynamic from "next/dynamic";
import { useMediaQuery } from "react-responsive";
import {
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
  useCallback,
} from "react";
import { PageContext } from "./providers";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";
import { PALETTE } from "./_lib/constant/palette";
import Link from "next/link";
import Head from "next/head";
import { COUNTRY_LIST } from "./_lib/constant/country-constant";
import { useRouter } from "next/navigation";
import api from "./_lib/api/api";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from "react-player";
import { Translator } from "./_lib/langugage/language";

export default function Page() {
  const router = useRouter();
  const { user, country, setUser }: any = useContext(PageContext);
  useLayoutEffect(() => {
    // if (user?.id === -1) router.push("/login");
  }, []);

  const [isMobileChk, setIsMobileChk] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });
  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)",
  });
  useEffect(() => {
    // mobile 쿼리로 인해 값이 바뀔 때 수행
    if (isMobile) setIsMobileChk(true);
    else setIsMobileChk(true);
  }, [isMobile]);

  const [creatorList, setCreatorList] = useState([]);
  const [banner, setBanner] = useState([]);
  const [pictorialList, setPictorialList] = useState([]);

  const pageSize = 20;
  const pageNum = useRef(0);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/home/getBanner", {
          params: {},
        })
        .then((res: any) => {
          setBanner(res.data.banner);
        });
      await api
        .get("/home/getCreatorList", {
          params: {},
        })
        .then((res: any) => {
          setCreatorList(res.data.creatorList);
        });
      await api
        .get("/home/getPictorialList", {
          params: {
            pageSize,
            pageNum: 0, //pageNum.current
          },
        })
        .then((res: any) => {
          pageNum.current = 1;
          setPictorialList(res.data.pictorialList);
        });
    }

    fetchData();
  }, []);

  const [fetching, setFetching] = useState(false);
  let fetchingR = false;

  const handleScroll = (e: any) => {
    let scrollLocation = document.documentElement.scrollTop; // 현재 스크롤바 위치
    let windowHeight = window.innerHeight; // 스크린 창
    let fullHeight = document.body.scrollHeight; //  margin 값은 포함 x

    if (
      scrollLocation + windowHeight >= fullHeight * 0.98 &&
      fetching === false
    ) {
      if (fetchingR === false) {
        setFetching(true);
      }
      fetchingR = true;
    } else {
      fetchingR = false;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    async function fetchData() {
      await api
        .get("/home/getPictorialList", {
          params: {
            pageSize,
            pageNum: pageNum.current,
          },
        })
        .then((res: any) => {
          pageNum.current = pageNum.current + 1;
          setPictorialList(pictorialList.concat(res.data.pictorialList));
        });
    }
    if (fetching === true) {
      fetchData();
    }
  }, [fetching]);

  return isMobileChk ? (
    <main className="AppBody">
      <div
        style={{
          // height: "100vh",
          overflowX: "hidden",
          overflowY: "scroll",
          borderRadius: 0,
        }}
      >
        <div
          className="landing"
          style={{
            borderRadius: 0,
            backgroundColor: PALETTE.COLOR_MAIN,
          }}
        >
          {/*1*/}
          <div
            className="box"
            style={{
              borderRadius: 0,
              backgroundColor: "black", //PALETTE.COLOR_MAIN,
            }}
          >
            <div className="landing">
              <div
                className="landing-inner"
                style={{
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    // background: "red",
                    minHeight: "6vh",
                    height: 80,
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingLeft: 16,
                    paddingRight: isMobile ? 0 : 16,
                  }}
                >
                  <div
                    style={{
                      borderRadius: 100,
                    }}
                  >
                    <img
                      src="/assets/main/logo.jpeg"
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: 100,
                        aspectRatio: "1/1",
                        objectFit: "cover",
                      }}
                    ></img>
                  </div>
                  {!isMobile && (
                    <div
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        backgroundColor: PALETTE.COLOR_BACKB2,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: 50,
                        height: 50,
                        minWidth: 400,
                      }}
                    >
                      <input
                        style={{
                          color: "white",
                          borderRadius: 100,
                          height: 45,
                          minWidth: 350,
                          backgroundColor: PALETTE.COLOR_BACKB2,
                          border: "none",
                        }}
                        placeholder={Translator(
                          "검색어를 입력해주세요.",
                          country
                        )}
                      ></input>
                      <img
                        style={{
                          marginLeft: 10,
                          width: 30,
                          height: 30,
                        }}
                        onClick={() => {
                          window.alert(Translator("준비중 입니다!", country));
                        }}
                        src="/assets/main/search.png"
                      ></img>
                    </div>
                  )}
                  <div
                    style={{
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "row",
                        alignContent: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link
                        href={"/cart"}
                        style={{
                          textDecoration: "none",
                          marginRight: 15,
                          borderRadius: 10,
                          border: isMobile ? "none" : "1px solid #d3d3d3",
                          width: isMobile ? 40 : 70,
                          height: 55,
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          backgroundColor: "black",
                        }}
                      >
                        <img
                          src="/assets/main/shop.png"
                          style={{
                            width: 25,
                            height: 25,
                          }}
                        ></img>
                        {!isMobile && (
                          <div
                            style={{
                              fontSize: 14,
                              marginTop: 2,
                              color: "white",
                            }}
                          >
                            {Translator("장바구니", country)}
                          </div>
                        )}
                      </Link>
                      <Link
                        href={"/setting"}
                        style={{
                          textDecoration: "none",
                          marginRight: isMobile ? 10 : 15,
                          borderRadius: 10,
                          border: isMobile ? "none" : "1px solid #d3d3d3",
                          width: isMobile ? 40 : 70,
                          height: 55,
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          backgroundColor: "black",
                        }}
                      >
                        <img
                          src="/assets/main/profile.png"
                          style={{
                            width: 25,
                            height: 25,
                          }}
                        ></img>
                        {!isMobile && (
                          <div
                            style={{
                              fontSize: 14,
                              marginTop: 2,
                              color: "white",
                            }}
                          >
                            {Translator("설정", country)}
                          </div>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  // borderTop: "1px solid black",
                  width: "100%",
                  //minHeight: "14vh",
                  //height: 140,
                  marginTop: 10,
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  overflowX: "scroll",
                  // background: PALETTE.COLOR_MAIN,
                  background: "black",
                }}
              >
                <div
                  className="landing-inner"
                  style={{
                    paddingTop: isMobile ? 0 : 20,
                    paddingBottom: 20,
                    paddingLeft: isMobile ? 16 : 16,
                    paddingRight: isMobile ? 16 : 16,
                    background: "black",
                    // background: PALETTE.COLOR_MAIN,
                    zIndex: 2,
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      marginBottom: isMobile ? 10 : 30,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                        color: "white",
                      }}
                    >
                      {Translator("크리에이터", country)}
                    </div>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      overflowX: "scroll",
                      width: "100%",
                      paddingRight: 30,
                    }}
                  >
                    {creatorList?.map((list: any, idx: number) => (
                      <Link
                        href={`/profile?link=${list?.link}`}
                        style={{
                          textDecoration: "none",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          marginRight: 20,
                        }}
                      >
                        <div
                          style={{
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            padding: 2,
                            borderRadius: 100,
                            backgroundColor: "white", // PALETTE.COLOR_BUTTON, //"#A40023",
                          }}
                        >
                          <img
                            style={{
                              aspectRatio: "1/1",
                              width: isMobile ? 50 : 70,
                              height: isMobile ? 50 : 70,
                              objectFit: "cover",
                              borderRadius: 100,
                              backgroundColor: "black",
                            }}
                            src={list?.profile}
                          ></img>
                        </div>
                        <div
                          style={{
                            marginTop: 5,
                            fontSize: 13,
                            color: "white",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                          }}
                        >
                          {list?.nick}
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="landing-inner"
                style={{
                  width: isPc ? "100%" : "100%",
                  // paddingLeft: isMobile ? 16 : 0,
                  // paddingRight: isMobile ? 16 : 0,
                }}
              >
                <Carousel
                  preventMovementUntilSwipeScrollTolerance={true}
                  swipeScrollTolerance={50}
                  showStatus={true}
                  showThumbs={false}
                  showIndicators={true}
                  autoPlay={true}
                  transitionTime={1000}
                  infiniteLoop={true}
                  // infiniteLoop={true}
                  // className="makePostP2"
                  //selectedItem={0}
                >
                  {banner?.map((list: any, idx: number) => (
                    <div
                      style={{
                        marginLeft: isPc ? 0 : "2%",
                        marginRight: isPc ? 0 : "2%",
                      }}
                    >
                      <img
                        src={isMobile ? list?.mobile : list?.pc}
                        style={{
                          borderRadius: isMobile ? 15 : 15,
                          width: "100%",
                          height: "55vh",
                          objectFit: "cover",
                        }}
                      ></img>
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div
          className="landing"
          style={{
            backgroundColor: "black",
            paddingTop: 30,
            paddingBottom: 30,
          }}
        >
          <div
            className="landing-inner"
            style={{
              zIndex: 2,
              backgroundColor: "black",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                justifyContent: isMobile ? "space-evenly" : "flex-start",

                flexWrap: "wrap",
              }}
            >
              {pictorialList?.map((list: any, idx: number) => (
                <Link
                  href={`/pictorial?id=${list?.id}`}
                  style={{
                    textDecoration: "none",
                    backgroundColor: PALETTE.COLOR_MAIN,
                    width: isMobile ? "96%" : "24%",
                    marginRight: isMobile ? "0%" : "1%",
                    // padding:10,
                    //border: "1px solid black",
                    marginBottom: 30,
                    borderRadius: isMobile ? 10 : 10,
                  }}
                >
                  <img
                    src={list?.Containers[0]?.url}
                    style={{
                      width: "100%",
                      aspectRatio: "1/1.5",
                      objectFit: "cover",
                      borderTopLeftRadius: isMobile ? 10 : 10,
                      borderTopRightRadius: isMobile ? 10 : 10,
                    }}
                  ></img>
                  <div>
                    <div
                      style={{
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 20,
                        // maxHeight: 50,
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                        wordBreak: "break-word",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {list?.title}
                    </div>
                    <div
                      style={{
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 10,
                        paddingBottom: 10,
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={list?.User?.profile}
                        style={{
                          border: "1px solid white",
                          width: "12%",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                          borderRadius: 100,
                        }}
                      ></img>
                      <div
                        style={{
                          marginLeft: 10,
                          fontSize: 14,
                        }}
                      >
                        {list?.User?.nick}
                      </div>
                    </div>
                    <div
                      style={{
                        borderTop: "1px solid black",
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderRight: "1px solid black",
                          width: "50%",
                          height: 50,
                          padding: 8,
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/assets/main/starB.png"
                            style={{
                              width: 20,
                              height: 20,
                            }}
                          ></img>
                          <div
                            style={{
                              marginLeft: 5,
                              fontSize: 14,
                              fontWeight: "bold",
                            }}
                          >
                            {`${list?.avgScore} (${list?.lengthScore})`}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "50%",
                          height: 50,
                          padding: 8,
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <div
                          style={{
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/assets/main/price.png"
                            style={{
                              width: 30,
                              height: 30,
                            }}
                          ></img>
                          <div
                            style={{
                              marginLeft: 5,
                              fontSize: 13,
                              fontWeight: "bold",
                            }}
                          >
                            KRW {Number(list?.cost).toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div
          className="landing"
          style={{
            backgroundColor: PALETTE.COLOR_MAIN,
            scrollSnapAlign: "end",
            width: "100%",
            borderTop: "2px solid black",
          }}
        >
          <div className="landing-inner">
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                height: "40vh",
                width: "100%",
                paddingTop: "5vh",
                paddingBottom: "5vh",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    marginRight: isMobile ? 10 : 20,
                    fontSize: isMobile ? 20 : 36,
                  }}
                  onClick={() => {
                    // window.open("https://nmoment.live/app");
                  }}
                >
                  INSTAGRAM
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    marginRight: isMobile ? 10 : 20,
                    fontSize: isMobile ? 20 : 36,
                  }}
                  onClick={() => {
                    // window.open("https://nmoment.live/app");
                  }}
                >
                  ARTICLE
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    marginRight: isMobile ? 10 : 20,
                    fontSize: isMobile ? 20 : 36,
                  }}
                  onClick={() => {
                    // window.open("https://nmoment.live/app");
                  }}
                >
                  CAREER
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    className="teko-Logo"
                    style={{
                      fontSize: 18,
                      marginBottom: 2,
                    }}
                  >
                    contact
                  </div>
                  <div
                    className="teko-Logo"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    npickcompany@gmail.com
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                  }}
                >
                  주식회사 엔픽컴퍼니
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                  }}
                >
                  대표자: 박세정
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                  }}
                >
                  유선 전화번호: 031-842-5253
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                  }}
                >
                  사업자등록번호: 894-86-02799
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                  }}
                >
                  주소: 서울특별시 마포구 마포대로 49, 502호(도화동, 성우빌딩)
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                    fontWeight: "bold",
                  }}
                >
                  © (주)엔픽컴퍼니 All rights reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <div></div>
  );
}
